import React, { Suspense } from 'react'; 
import { SliderData } from './components/SliderData';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import LoadingSpinner from './components/LoadingSpinner'; 

const Navbar = React.lazy(() => import('./components/Navbar/Navbar'));
const Home = React.lazy(() => import('./components/Home')); 
const About = React.lazy(() => import('./components/About')); 
const Contact = React.lazy(() => import('./components/Contact')); 
const Gallery = React.lazy(() => import('./components/Gallery')); 
const Footer = React.lazy(() => import('./components/Footer')); 


function App() {
  return (
    <Router>
      <Suspense fallback={
        <div>
          <LoadingSpinner />
        </div>
      }>
      <div className="App">
        <Navbar />
        <div className="content">
          <Switch>
            <Route exact path="/">
              <Home />
            </Route>
            <Route path="/about">
              <About />
            </Route>
            <Route path="/contact">
              <Contact />
            </Route>
            <Route path="/gallery">
            <Gallery slides={SliderData} />
            </Route>
          </Switch>
        </div>
        <Footer /> 
      </div>
      </Suspense> 
    </Router>
  );
}

export default App;
