import Bathroom from '../Images/Bathroom.jpeg';
import Bathroom2 from '../Images/Bathroom2.jpeg';
import Entrance from '../Images/Entrance.jpeg';
import Entrance2 from '../Images/Entrance2.jpeg';
import FamilyRoom from '../Images/FamilyRoom.jpeg';
import FamilyRoom2 from '../Images/FamilyRoom2.jpeg';
import FamilyRoom3 from '../Images/FamilyRoom3.jpeg';
import Kitchen from '../Images/Kitchen.jpeg';
import Kitchen2 from '../Images/Kitchen2.jpeg';
import Private from '../Images/Private.jpeg';
import Private2 from '../Images/Private2.jpeg';
import SemiPrivate from '../Images/SemiPrivate.jpeg';
import SemiPrivate2 from '../Images/SemiPrivate2.jpeg';

export const SliderData = [
    {
        image: `${Entrance2}`, 
        description: "Facility Entrance",
        type: "portrait", 
    }, 
    {
        image: `${Entrance}`, 
        description: "Facility Entrance",
        type: "portrait"
    }, 
    {
        image: `${FamilyRoom}`,
        description: "Family Room", 
        type:"landscape"
    }, 
    {
        image: `${FamilyRoom2}`,
        description: "Family Room", 
        type:"landscape"
    }, 
    {
        image: `${FamilyRoom3}`,
        description: "Family Room",
        type:"landscape"
    }, 
    {
        image: `${Kitchen}`,
        description: "Kitchen & Dining Area", 
        type:"landscape"
    }, 
    {
        image: `${Kitchen2}`,
        description: "Kitchen & Dining Area",
        type:"landscape"
    }, 
    {
        image:`${Private}`,
        description: "Private Room",
        type:"landscape"
    }, 
    {
        image: `${Private2}`,
        description: "Private Room", 
        type: "portrait",
    }, 
    {
        image: `${SemiPrivate}`,
        description: "Semi-Private Room",
        type:"landscape"
    }, 
    {
        image: `${SemiPrivate2}`,
        description: "Semi-Private Room",
        type:"landscape"
    }, 
    {
        image: `${Bathroom}`,
        description: "Bathroom",
        type:"landscape"
    }, 
    {
        image: `${Bathroom2}`,
        description: "Bathroom",
        type:"landscape"
    }
]; 